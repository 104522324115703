(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/radio-buttons/assets/javascripts/radio-buttons.js') >= 0) return;  svs.modules.push('/components/lb-ui/radio-buttons/assets/javascripts/radio-buttons.js');
"use strict";


const getButtonClassName = (selectedIndex, index, branding, correctIndex, isDisabled, classNamePrefix) => {
  const classes = ["".concat(classNamePrefix ? "".concat(classNamePrefix, "_") : '', "radio_button")];
  if (branding) {
    classes.push("".concat(classNamePrefix ? "".concat(classNamePrefix, "_") : '', "radio_button--").concat(branding));
  }
  if (correctIndex === index) {
    classes.push("".concat(classNamePrefix ? "".concat(classNamePrefix, "_") : '', "radio_button--result"));
  }
  if (isDisabled) {
    classes.push("".concat(classNamePrefix ? "".concat(classNamePrefix, "_") : '', "radio_button--disabled"));
  }
  return classes;
};
const RadioButtons = _ref => {
  let {
    options,
    selectedIndex,
    handleClick,
    className = '',
    branding,
    correctIndex,
    disabledOptions,
    classNamePrefix
  } = _ref;
  const classNames = ["".concat(classNamePrefix ? "".concat(classNamePrefix, "_") : '', "radio_button_group")];
  if (branding) {
    classNames.push("".concat(classNamePrefix ? "".concat(classNamePrefix, "_") : '', "radio_button_group--").concat(branding));
  }
  if (className.length) {
    classNames.push(className);
  }
  return React.createElement("div", {
    className: classNames.join(' '),
    role: "radiogroup"
  }, options.map((option, index) => {
    const isDisabled = disabledOptions[options.indexOf(option)];
    return React.createElement("button", {
      "aria-checked": selectedIndex === index,
      className: getButtonClassName(selectedIndex, index, branding, correctIndex, isDisabled, classNamePrefix).join(' '),
      disabled: isDisabled,
      key: option.text,
      onClick: () => handleClick(index),
      role: "radio",
      type: "button"
    }, option.text);
  }));
};
setGlobal('svs.lb_ui.radioButtons.RadioButtons', RadioButtons);

 })(window);